import React from 'react'
import { ButtonPrimary } from '../components/atoms/Button/ButtonPrimary'
import { LayoutNoSideNav } from '../components/organisms/Layout/LayoutNoSideNav'

const Page = () => {
  return (
    <LayoutNoSideNav
      heading="404 NOT FOUND"
      headingEnglish="404 NOT FOUND"
      BreadcrumbData={[{ title: '404', url: '' }]}
    >
      <div className="py-20 text-center pc:py-32">
        <h1 className="text-[64px] font-semibold text-primary pc:text-[120px]">
          404
          <span className="block text-[24px] font-semibold leading-[56px] pc:text-[40px]">
            NOT FOUND
          </span>
        </h1>
        <p className="mt-6 text-base font-bold pc:text-2xl">
          お探しのページが見つかりません。
        </p>
        <p className="mt-8 mb-10 text-center text-md leading-normal pc:mb-20">
          URL、ファイル名にタイプミスがないかご確認ください。
          <br />
          指定されたページは削除されたか、移動した可能性があります。
        </p>
        <ButtonPrimary url="/">トップに戻る</ButtonPrimary>
      </div>
    </LayoutNoSideNav>
  )
}

export default Page

export const Head = () => {
  return (
    <>
      <title>404 NOT FOUND | 総合人材サービスのCRGホールディングス</title>
      <meta
        name="keywords"
        content="人材派遣,総合アウトソーシング,CRGホールディングス"
      />
      <meta
        name="description"
        content="CRGホールディングスのWebサイトです。お探しのページは見つかりません。。"
      />
      <meta
        property="og:title"
        content="404 NOT FOUND | 総合人材サービスのCRGホールディングス"
      />

      <meta property="og:type" content="article" />

      <meta property="og:url" content="https://www.crgh.co.jp/404.html" />
      <meta
        property="og:image"
        content="https://www.crgh.co.jp/common/img/og_image.png"
      />
      <meta property="og:site_name" content="CRGホールディングス" />
      <meta
        property="og:description"
        content="CRGホールディングスのWebサイトです。お探しのページは見つかりません。。"
      />
      {/* 20230926 追加 */}
      <meta
        name="facebook-domain-verification"
        content="ty5782t7m4xyz5t30mnss9u10t9lgh"
      />
    </>
  )
}
